import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";
import {TID} from "../misc/lists/ListCommon";
import {PrepDetail} from "./QuoteHardware";

export class Openings extends SubAPI {
    list(input: {
        project: number
        type?: "for-release" | "have-no-hardware-group" | "add-hw-find-opening-number";
        category?: "frame" | "door" | "hardware";
        offset?: number;
        search?: string;
        previewChangeset?: number;
        hwGroup?: number;
    }) {
        return this.fetcher.get<Paginated<Opening>>("/api/openings", input)
    }

    listScreenElevations(input: {
        project: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<ScreenElevation>>("/api/opening/screen-elevations", input)
    }

    updateScreenElevation(input: ScreenElevation) {
        return this.fetcher.post<{}>("/api/opening/screen-elevation", input)
    }

    listDrawingWallLabels(input: {
        project: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<DrawingWallLabel>>("/api/opening/drawing-wall-labels", input)
    }

    updateDrawingWallLabel(props: DrawingWallLabel) {
        return this.fetcher.post<{}>("/api/opening/drawing-wall-label", props)
    }

    listDoorElevations(input: {
        project: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<DoorElevation>>("/api/opening/door-elevations", input)
    }

    updateDoorElevation(input: DoorElevation) {
        return this.fetcher.post<{}>("/api/opening/door-elevation", input)
    }

    upsert(input: Opening | {project: number}) {
        return this.fetcher.post<TID>("/api/opening", input)
    }

    reSequence(input: {
        project: number
        idOrder: number[]
    }) {
        return this.fetcher.post<{}>("/api/opening/re-sequence", input)
    }

    reOrder(input: {
        project: number;
        idOrder: number[]
    }) {
        return this.fetcher.post<{}>("/api/opening/re-order", input)
    }

    release(input: {
        authorizedBy: string;
        note: string;
        project: number;
        neededBy: null | DateString;

        doorForOpenings?: number[];
        frameForOpenings?: number[];
        hardwareForOpenings?: number[];
        div10Items?: number[];

        type?: string;
        requests?: number[];

        unRelease: boolean;
        confirmUnreleasePODraft?: boolean;
    }) {
        return this.fetcher.post<TID & {
            confirmUnreleasePODraft?: string;
            hasNonStockingAvailable: boolean;
            releaseId: number;
        }>("/api/release", input)
    }

}

export type ScreenElevation = {
    id: number;
    name: string;
    file?: number;
    fileName?: string;
    project: number;

    screenElevation: string;
    screenElevationFile?: number;
    screenElevationFileName?: string;
    screenElevationOutsideHeight: string;
    screenElevationOutsideWidth: string;

    width?: string;
    height?: string;
}

export type DoorElevation = {
    id: number;
    name: string;
    file?: number;
    fileName?: string;
    project: number;

    doorElevation: string;
    doorElevationFile?: number;
    doorElevationFileName?: string;
}

export type DrawingWallLabel = {
    id: number;
    name: string;
    file?: number;
    fileName?: string;
    drawingWallLabel: string;
    drawingWallLabelFile?: number;
    drawingWallLabelFileName?: string;
    project: number;

    jambDepth: string;
    frameAnchorProduct?: number;
    frameAnchorProductName?: string;
}

export type Opening = {
    id: number;
    name: string;
    project: number;
    seqNumber: number;
    qty: number;

    hardwareGroup?: number;
    hardwareGroupName?: string;
    hardwareGroupPrepConfig?: PrepDetail[];
    hardwareGroupPrepConfigIsCustom: boolean;
    hwDescriptiveName?: string;

    frameRelease?: number;
    doorRelease?: number;
    isHardwareReleased?: boolean | null;

    locationOne: string;
    locationTransition: "to"|"from"|"";
    locationTwo: string;
    floor: string;
    exterior: boolean;
    openingType: string;
    nominalWidth: string;
    inactiveDoorWidth: string;
    nominalHeight: string;
    handing: string;
    flipDoubleActiveHanding: boolean;
    label: string;
    frameSeries: string;
    frameMaterial: string;
    frameGauge: string;

    screenElevation: string;
    screenElevationFileName?: string;
    screenElevationFile?: number;
    screenElevationOutsideWidth: string;
    screenElevationOutsideHeight: string;

    frameProfile: string;
    jambDepth: string;

    drawingWallLabel: string;
    drawingWallLabelFile?: number;
    drawingWallLabelFileName?: string;

    frameConstruction: string;
    frameHardwarePreps: string;
    doorSeries: string;
    doorMaterial: string;
    doorGauge: string;
    doorThickness: string;
    doorElevation: string;
    doorElevationFile?: number;
    doorElevationFileName?: string;
    doorCore: string;
    doorSeam: string;
    doorFinish: string;
    notes: string;

    frameAnchorProduct?: number;
    frameAnchorProductName?: string;
    frameAnchorDetailFile?: number;
    frameAnchorQtyOverride: number | null;

    sortOrder: number;

    frameStatus: string;
    doorStatus: string;

    createdAt: DateString;
    updatedAt: DateString;
    archived: boolean;

    historyCount: number;

    backup: Opening | null;
}